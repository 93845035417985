import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Icon from "../utilities/icons"
import styled from "styled-components"
import SEO from "../components/seo"
import Img from "gatsby-image"

const GalleryGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .instagram_square {
    width: calc(33.33% - 12px);
    background-size: cover;
    object-fit: cover;
    margin-bottom: 18px;
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    @media (max-width: 620px) {
      width: calc(50% - 10px);
      margin-bottom: 20px;
    }
  }
`

const Gallery = props => {
  /*const instaPosts = props.data.allInstaNode.edges

  console.dir(instaPosts)

  return (
    <Layout>
      <SEO
        title="Gallery |  MJ Electrical | Electrician in Newark"
        description="Gallery offered by MJ Electrical - Electrician in Newark on Trent and surrounding areas. Covering Nottinghamshire, Lincolnshire, Leicestershire, Derbyshire"
      />
      <div className="standard-page-container">
        <div className="inner-container">
          <h1>Gallery</h1>
          <GalleryGrid style={{}}>
            {instaPosts.map((post, i) => (
              <div
                key={post.node.id}
                className="instagram_square"
                style={{
                  backgroundImage: `url(${post.node.preview})`,
                }}
              ></div>
            ))}
          </GalleryGrid>

          <div
            style={{ marginTop: "20px", textAlign: "center" }}
            className="global-btn-wrapper "
          >
            <a
              target="_blank"
              href="https://www.instagram.com/electrical_mj/"
              className="global-btn global-btn_orange"
            >
              View Full Instagram Gallery
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )*/

  return (
    <Layout>
      <SEO
        title="Gallery |  MJ Electrical | Electrician in Newark"
        description="Gallery offered by MJ Electrical - Electrician in Newark on Trent and surrounding areas. Covering Nottinghamshire, Lincolnshire, Leicestershire, Derbyshire"
      />
      <div className="standard-page-container">
        <div className="inner-container">
          <h1>Gallery</h1>
          <p>Gallery Coming Soon...</p>
        </div>
      </div>
      </Layout>
  )

}

export default Gallery

/*
export const query = graphql`
  query {
    allInstaNode {
      edges {
        node {
          id
          mediaType
          preview
          original
          timestamp
          localFile {
            childImageSharp {
              fixed(width: 600, height: 600) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          # Only available with the public api scraper
          thumbnails {
            src
            config_width
            config_height
          }
          dimensions {
            height
            width
          }
        }
      }
    }
  }
`
*/